import { Configure } from "react-instantsearch-dom";

import RefinementReset from "./RefinementReset";
import Categories from "./Categories";
// import Filters from "./Filters";

const Refinement = () => {
  return (
    <div className="slds-p-right_x-large">
      <Configure hitsPerPage={20} />
      {/* <Filters /> */}
      <div className="slds-m-bottom_x-large"></div>
      <Categories />

      <RefinementReset />
    </div>
  );
};

export default Refinement;
