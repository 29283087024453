import { SourceProductResponse, SummaryResult } from "./types";
import LRU from "lru-cache";

// @ts-nocheck
declare var SObjectModel: any;
declare var Visualforce: any;
declare var searchController: any;

const cache = new LRU({ max: 100 });

let inVF = window.location.href.indexOf("localhost") > 0 ? false : true;

export function getProducts() {
  // if (!inVF) {  //local - use axios
  //  return axios.get(`${ROOT_URL}/posts?query=SELECT Id, Name,Categories__c FROM Post__c`);
  // }

  // Wrap RemoteObject's function in a Promise
  return new Promise((resolve, reject) => {
    var post = new SObjectModel.Product();

    // Use the Remote Object to query for 10 items records
    post.retrieve({ limit: 10 }, function (err, records, event) {
      if (err) {
        alert(err.message);
        return reject({ data: { message: err.message }, status: 400 });
      }
      //convert result object to Array to match axios+jsforce(local setup)
      var localRecords = event.result.records;
      var products = Object.keys(localRecords).map(function (key) {
        return records[key];
      });

      return resolve({ data: { records: products } }); //note: wrapping in 'data' to match axios + jsforce local setup
    });
  });
}

export function getProduct(id): Promise<SourceProductResponse> {
  // Wrap RemoteObject's function in a Promise
  return new Promise((resolve, reject) => {
    if (cache.has(id)) {
      return resolve(cache.get(id));
    }

    if (!inVF) {
      //local - use sample object for testing
      //console.log("Running in localhost");
      return resolve({
        data: {
          Cohort__c: "a0B0h00000o68tcEAA",
          Location2__c: "a0C1A00000gfqU7UAI",
          ProductCode: "306609",
          Main_Category_Product_Page_Layout__c: "a1k0h000005lFwpAAE",
          Id: "01t0h000004fkXjAAI",
          RecordTypeId: "012C00000004nOMIAY",
          Sold_Price__c: 123433,
          CompanyofSoldTo__c: "My company",
          Asking_Price__c: 456777,
        },
      });
    }

    var post = new SObjectModel.Product();

    // Retreive post by id
    post.retrieve(
      { where: { Id: { eq: id } } },
      function (err, records, event) {
        if (err) {
          alert(err.message);
          return reject({ data: { message: err.message }, status: 400 });
        }
        //convert result object to Array to match axios+jsforce(local setup)
        var record = event.result.records["0"] || {};

        cache.set(id, { data: record });
        return resolve({ data: record }); //note: wrapping in 'data' to match axios + jsforce local setup
      }
    );
  });
}

export function getSummaryPrices(
  productIds: Array<string>
): Promise<SummaryResult> {
  return new Promise((resolve, reject) => {
    //console.log("getAvgPrice");
    if (!inVF) {
      resolve({ min: 0, max: 0, avg: 0 });
    }
    Visualforce.remoting.Manager.invokeAction(
      searchController,
      productIds,
      (result, event) => {
        if (event.status) {
          resolve({
            avg: Number(result.avg),
            min: Number(result.min),
            max: Number(result.max),
          });
        } else if (event.type === "exception") {
          reject();
        } else {
          reject(event.message);
        }
      }
    );
  });
}
