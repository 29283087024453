import { CurrentRefinementsProvided } from "react-instantsearch-core";
import { connectCurrentRefinements } from "react-instantsearch-dom";
import Button from "@salesforce/design-system-react/components/button";

const RefinementReset = connectCurrentRefinements(
  ({ items, refine }: CurrentRefinementsProvided) => {
    return (
      <div className="slds-grid">
        <Button
          className="slds-col slds-m-bottom_large"
          onClick={() => refine(items)}
          disabled={items.length <= 0}
        >
          Clear
        </Button>
      </div>
    );
  }
);

export default RefinementReset;
