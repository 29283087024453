export interface Props {
  role: "group";
  level: number;
  expanded: boolean;
  children?: JSX.Element | JSX.Element[];
}

const TreeBranch = (props: Props) => {
  return (
    <ul role="group">
      <li aria-level={props.level} role="treeitem">
        {props.children}
      </li>
    </ul>
  );
};

export default TreeBranch;
