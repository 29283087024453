import { connectSearchBox } from "react-instantsearch-dom";
import Input from "@salesforce/design-system-react/components/input";
import Button from "@salesforce/design-system-react/components/button";
import { SearchBoxProvided } from "react-instantsearch-core";
import { FormEvent } from "react";

const SearchInput = ({ currentRefinement, refine }: SearchBoxProvided) => {
  function onChange(evt: FormEvent<HTMLInputElement>) {
    refine(evt.currentTarget.value);
  }

  function reset() {
    refine("");
  }

  return (
    <div className="sf-layout slds-col slds-size_12-of-12">
      <div className="slds-grid">
        <Input
          placeholder="Search query..."
          onChange={onChange}
          value={currentRefinement}
          className="slds-col slds-size_11-of-12"
        />
        <Button
          onClick={reset}
          className="slds-m-left_x-small slds-col slds-size_1-of-12"
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default connectSearchBox(SearchInput);
