import { connectHierarchicalMenu } from "react-instantsearch-dom";
import { Hit } from "react-instantsearch-core";
import Checkbox from "@salesforce/design-system-react/components/checkbox";

import { Tree, TreeLeaf, TreeBranch } from "../Tree";

type Item = Hit<{
  count: number;
  isRefined: boolean;
  label: string;
  value: string[];
}>;

interface FieldProps {
  item: Item;
  refine: any;
}

const CheckField = ({ item, refine }: FieldProps) => {
  return (
    <Checkbox
      labels={{ label: `${item.label} (${item.count})` }}
      checked={item.isRefined}
      onChange={(evt) => refine(item.value)}
    />
  );
};

const RecursiveTree = ({ items, refine, level }) => {
  return (
    <>
      {items.map((e) => (
        <TreeBranch
          role="group"
          level={level}
          expanded={items}
          key={`${e.label}_${level}`}
        >
          <TreeLeaf
            label={e.label}
            component={<CheckField item={e} refine={refine} />}
          />
          {e.items && (
            <RecursiveTree items={e.items} refine={refine} level={level + 1} />
          )}
        </TreeBranch>
      ))}
    </>
  );
};

const RefinementTree = connectHierarchicalMenu((props) => {
  return (
    <div className="slds-p-bottom_x-large sf-refinement-list">
      <legend className="slds-p-bottom_xx-small slds-text-heading_small">{props.category}</legend>
      <Tree>
        <RecursiveTree items={props.items} refine={props.refine} level={0} />
      </Tree>
    </div>
  );
});

export default RefinementTree;
