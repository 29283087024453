import { connectRefinementList } from "react-instantsearch-dom";
import { RefinementListProvided } from "react-instantsearch-core";

import Checkbox from "@salesforce/design-system-react/components/checkbox";
import Input from "@salesforce/design-system-react/components/input";

interface RefinementProps extends RefinementListProvided {
  category: string;
  searchable?: boolean | undefined;
}

const RefinementList = connectRefinementList((props: RefinementProps) => {
  if (props.items.length === 0) return <></>;

  return (
    <div className="slds-p-bottom_x-large">
      <fieldset>
        {(props.searchable || props.searchable === undefined) && (
          <Input
            placeholder={`Search ${props.category}s`}
            type="search"
            className="slds-m-bottom_small slds-m-top_x-small"
            onChange={(event) =>
              props.searchForItems(event.currentTarget.value)
            }
          />
        )}
        <legend className="slds-p-bottom_xx-small slds-text-heading_small">
          {props.category}
        </legend>
        {props.items.map((item) => (
          <Checkbox
            key={item.label}
            labels={{ label: `${item.label} (${item.count})` }}
            checked={item.isRefined}
            onChange={(evt) => {
              props.refine(item.value);
            }}
          />
        ))}
      </fieldset>
    </div>
  );
});

export default RefinementList;

