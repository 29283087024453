import { Facets } from "../../search/facets";
import RefinementList from "./RefinementList";
import RefinementTree from "./RefinementTree";

const Categories = () => {
  return (
    <>
      <RefinementList
        attribute={Facets.MStatus}
        category="Status"
        limit={15}
        showMoreLimit={100}
        searchable={false}
      />

      <RefinementTree
        attributes={[Facets.Category0, Facets.Category1, Facets.Category2]}
        category="Category"
        limit={25}
      />

      <RefinementList
        attribute={Facets.Manufacturer}
        category="Manufacturer"
        limit={15}
        showMoreLimit={100}
      />

      {/* <RefinementList attribute={Facets.Cohort} category="Cohort" limit={15} /> */}
      <RefinementList attribute={Facets.Model} category="Model" limit={20} />
      {/* <RefinementList attribute={Facets.SoldYear} category="Sold Year" limit={15} /> */}
    </>
  );
};

export default Categories;
