import Layout from "./components/Layout";

function App() {
  return (
    <div className="slds-container_center slds-text-body slds-p-around_large">
      <Layout />
    </div>
  );
}

export default App;
