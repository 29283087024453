import algolia from "algoliasearch";
import type { SearchClient, SearchIndex } from "algoliasearch";

export class Client {
  public readonly client: SearchClient;
  public readonly index: SearchIndex;

  constructor(appId: string, key: string, index: string) {
    this.client = algolia(appId, key);
    this.index = this.client.initIndex(index);
  }

  static fromEnv(): Client {
    const name = process.env["REACT_APP_ALGOLIA_APP_NAME"];
    const key = process.env["REACT_APP_ALGOLIA_SEARCH_KEY"];
    const index = process.env["REACT_APP_ALGOLIA_INDEX"];

    if (!name || !key || !index)
      throw new Error("Algolia key, name or index missing");
    return new Client(name, key, index);
  }
}

let client: Client | undefined;
export function getClient(): Client {
  if (client) return client;

  client = Client.fromEnv();
  return client;
}
