export enum Facets {
  Category0 = "categories.lvl0",
  Category1 = "categories.lvl1",
  Category2 = "categories.lvl2",
  Manufacturer = "manufacturerName",
  Model = "model",
  Status = "productStatus",
  SoldYear = "sold_year",
  Cohort = "cohort",
  OnPublicSite = "onPublicSite",
  MStatus = "mStatus",
}

export const BASE_CATEGORY = "categories.lvl";
