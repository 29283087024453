import { useImage } from "react-image";
import { Item } from "./types";
import { useEffect, useState } from "react";
import Details, { Link, Text } from "./Details";

import Spinner from "@salesforce/design-system-react/components/spinner";
import { Highlight } from "react-instantsearch-dom";
import { getProduct } from "../../api";
import { SourceProduct } from "../../api/types";
import convertTimestamp from "../../util";

interface Props {
  hit: Item;
}

interface TextItemProps {
  label: string;
  text: string;
}

const TextItem = (item: TextItemProps) => {
  return (
    <td data-label={item.label}>
      <div className="slds-cell-wrap" title={item.text}>
        {item.text}
      </div>
    </td>
  );
};

interface NestedItemProps {
  label: string;
  title: string;
  nested: JSX.Element;
}

const NestedItem = (item: NestedItemProps) => {
  return (
    <td data-label={item.label}>
      <div className="" title={item.title}>
        {item.nested}
      </div>
    </td>
  );
};

const DelayedImg = ({ url }) => {
  const { src, isLoading } = useImage({
    srcList: url,
    useSuspense: false,
  });

  return (
    <div style={{ minHeight: "96px" }}>
      {isLoading ? <Spinner hasContainer /> : <img src={src} alt="" />}
    </div>
  );
};

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const View = ({ hit }: Props) => {
  const [sourceProduct, setSourceProduct] = useState<SourceProduct | null>(
    null
  );

  useEffect(() => {
    getProduct(hit.objectID).then((product) => {
      //console.log(`Product: ${JSON.stringify(product.data)}`);
      setSourceProduct(product.data);
    });
  }, [hit]);

  const details: [string, React.ReactElement][] = [
    ["Product Code", <Text text={sourceProduct?.ProductCode} />],
    ["On Public Site", <Text text={hit.onPublicSite ? "Yes" : "No"} />],
    [
      "Cohort",
      <Link text={hit.cohort} href={`/${sourceProduct?.Cohort__c}`} />,
    ],
    ["Company-sold", <Text text={sourceProduct?.CompanyofSoldTo__c} />],
  ];

  let subcatLookupFull = hit["categories.lvl2"];
  let subcatLookup = subcatLookupFull?.split(">");
  if (subcatLookup?.length === 3) {
    subcatLookup = subcatLookup[2];
  }

  return (
    <tr className="slds-hint-parent">
      <NestedItem
        label="Photo"
        title="Photo"
        nested={<DelayedImg url={hit.primaryPhoto} />}
      />
      <NestedItem
        label="Name"
        title="Name"
        nested={
        
            <Highlight hit={hit} attribute="name" tagName="slds-is-selected" />
     
        }
      />
      <TextItem
        label="Product Code"
        text={hit.productCode }
      />
       <TextItem
        label="Vendor ID"
        text={hit.vendorId ? hit.vendorId : ""}
      />
      <TextItem label="Manufacturer" text={hit.manufacturerName} />
      <TextItem label="Model" text={hit.model} />
      <TextItem label="Status" text={hit.mStatus ? hit.mStatus : ""} />
      {/* <TextItem
        label="Sold Date"
        text={convertTimestamp(hit?.sold_timestamp) || ""}
      /> */}
      {/* <TextItem
        label="Asking Price"
        text={
          sourceProduct && sourceProduct.Asking_Price__c
            ? formatter.format(sourceProduct.Asking_Price__c)
            : ""
        }
      /> */}

      {/* <TextItem
        label="Sold Price"
        text={
          sourceProduct && sourceProduct.Sold_Price__c
            ? formatter.format(sourceProduct.Sold_Price__c)
            : ""
        }
      /> */}

      {/* <NestedItem
        label="Sub-Category Lookup"
        title="Sub-Category Lookup"
        nested={
          <a
            className="slds-cell-wrap"
            href={`/${sourceProduct?.Sub_Category_Lookup__c}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {subcatLookup}
          </a>
        }
      /> */}
      {/* <NestedItem
        label="Details"
        title="Details"
        nested={<Details items={details} />}
      /> */}
    </tr>
  );
};

export default View;
