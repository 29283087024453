import { connectHits } from "react-instantsearch-dom";
import Header from "./Header";
import View from "./View";

import { Item } from "./types";

interface Props {
  hits: Array<Item>;
}

const ItemTable = (props: Props) => {
  return (
    <table className="sf-items-table slds-table slds-table_cell-buffer slds-no-row-hover slds-table_bordered">
      <Header />
      <tbody>
        {props.hits.map((e) => (
          <View hit={e} key={e.objectID} />
        ))}
      </tbody>
    </table>
  );
};

export default connectHits(ItemTable);
