import { connectPagination } from "react-instantsearch-core";

const usePagination = (total: number, selected: number) => {
  const MAX_ITEMS = 7;
  const MAX_OFFSET = 3;

  if (total === 0) return null;

  let result: Array<number | null> = [];

  // Too low amout of els
  if (total <= MAX_ITEMS) {
    return [...Array(total).keys()].map((e) => e + 1);
  }

  // Somewhere on the left side
  if (selected - 1 <= MAX_OFFSET) {
    result = [...Array(5).keys()].map((e) => e + 1);
    return result.concat([null, total]);
  }

  // Somewhere on the right side
  if (total - selected <= MAX_OFFSET) {
    let offset = total - MAX_OFFSET - 1;
    result = [1, null];
    return result.concat([...Array(5).keys()].map((e) => e + offset));
  }

  // Somewhere in the middle
  return [1, null, selected - 1, selected, selected + 1, null, total];
};

const NavItem = (props) => {
  function onClick(e) {
    e.preventDefault();
    if (props.onClick) props.onClick(e);
  }

  return (
    <li>
      <button
        className="blank_button slds-text-link slds-m-horizontal_x-small"
        type="button"
        onClick={onClick}
      >
        {props.component ?? props.text}
      </button>
    </li>
  );
};

const Pagination = ({ nbPages, currentRefinement, refine }) => {
  const pages = usePagination(nbPages, currentRefinement);
  if (!pages) return <></>;

  function onClicked(page: number) {
    return () => refine(page);
  }

  function next() {
    if (currentRefinement === nbPages) return;
    refine(currentRefinement + 1);
  }

  function prev() {
    if (currentRefinement === 1) return;
    refine(currentRefinement - 1);
  }

  return (
    <ul className="slds-list_horizontal slds-has-inline-block-links_space sf-pagination">
      <NavItem onClick={prev} text={"<"} />
      {pages.map((page, idx) => (
        <NavItem
          onClick={page ? onClicked(page) : undefined}
          key={page ?? 0 - idx}
          component={
            <>
              {page ? currentRefinement === page ? <b>{page}</b> : page : "..."}
            </>
          }
        />
      ))}
      <NavItem onClick={next} text={">"} />
    </ul>
  );
};

const Navigation = (props) => {
  // slds-col_bump-left
  return (
    <div className="slds-grid slds-m-bottom_large">
      <Pagination
        refine={props.refine}
        currentRefinement={props.currentRefinement}
        nbPages={props.nbPages}
      />
    </div>
  );
};

export default connectPagination(Navigation);
