import { useState, useContext } from "react";
import { IndexContext } from "../../search/IndexContext";

export enum Order {
  Asc = "asc",
  Desc = "desc",
  None = "none"
}

const BASE_INDEX = process.env["REACT_APP_ALGOLIA_INDEX"];

const useSorting = (defaultOrder?: Order, defaultIdx?: number) => {
  const [order, setOrder] = useState(defaultOrder ?? Order.Asc);
  const [selected, setSelected] = useState(defaultIdx ?? 0);
  const {index, setIndex : setGlobalIndex} = useContext(IndexContext);

  function toggle(idx: number, item: HeaderItem) {
    let newOrder: Order = order;
    if (idx === selected) {
      if (order === Order.Asc) {
        newOrder = Order.Desc;
      } else if (order === Order.Desc) {
        newOrder = Order.None;
      } else if (order === Order.None) {
        newOrder = Order.Asc;
      }
      //newOrder = order === Order.Asc ? Order.Desc : Order.Asc;
    } else {
      setIndex(idx);
      newOrder = order;
    }

    setOrder(newOrder);
    if ( newOrder === Order.Asc || newOrder === Order.Desc) {
      setGlobalIndex(`${BASE_INDEX}_${item.fieldName}_${newOrder.toString()}`);
    }
    else {
      setGlobalIndex(BASE_INDEX)
    }
  }

  function setIndex(idx) {
    setSelected(idx);
    setOrder(defaultOrder ?? Order.Asc);
  }

  return { toggle, order, selected };
};

type HeaderItem = { label: string; sortable: boolean, fieldName?: string | undefined | null };
const TABLE_HEADER: HeaderItem[] = [
  { label: "photo", sortable: false },
  { label: "name", sortable: false },
  { label: "product code", sortable: false },
  { label: "vendor Id", sortable: false },
  { label: "manufacturer", sortable: false },
  { label: "model", sortable: false },
  { label: "status", sortable: false },
  // { label: "sold date", sortable: true,  fieldName: "sold" },
  // { label: "asking price", sortable: true, fieldName: "askingPrice" },
  // { label: "sold price", sortable: true, fieldName: "soldPrice" },
  // { label: "cohort", sortable: true },

  // { label: "Sold Year", sortable: false },
  // { label: "details", sortable: false },
];

const Header = () => {
  const { toggle, selected, order } = useSorting(Order.None, 1);

  function orderDirection(idx: number) {
    if (idx !== selected) return;
    switch (order) {
      case Order.Asc:
        return <span className="sf-sort-symbol">&#8593;</span>;
      case Order.Desc:
        return <span className="sf-sort-symbol">&#8595;</span>;
    }
  }

  function toggleSort(idx: number, item: HeaderItem) {
    return () => {
      if (!TABLE_HEADER[idx].sortable) return;
      toggle(idx, item);
    };
  }

  function headerItem(item: HeaderItem, idx: number) {
    return (
      <th scope="col" key={item.label} onClick={toggleSort(idx, item)}>
        <div className="slds-truncate sf-header" title={item.label}>
          {item.label}
          {orderDirection(idx)}
        </div>
      </th>
    );
  }

  return (
    <thead>
      <tr className="slds-line-height_reset">{TABLE_HEADER.map(headerItem)}</tr>
    </thead>
  );
};

export default Header;
