export interface Props {
  label: string;
  component?: JSX.Element;
}

const TreeLeaf = (props: Props) => {
  return (
    <div className="slds-tree__item">
      <span className="slds-has-flexi-truncate">
        <span className="slds-tree__item-label" title={props.label}>
          {props.component ?? props.label}
        </span>
      </span>
    </div>
  );
};

export default TreeLeaf;
