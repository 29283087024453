import { useState, useContext } from "react";
import { InstantSearch } from "react-instantsearch-dom";
import { getClient } from "../search/algolia";
import { IndexContext } from "../search/IndexContext";

import ItemTable from "./ItemTable";
import Navigation from "./Navigation";
import Refinement from "./Refinement";
import SearchInput from "./Search";
import Summary from "./Summary";

const Layout = () => {
  const client = getClient();
  const { index } = useContext(IndexContext);

  const [locaIndex, setLocalIndex] = useState(index);

  const updateIndex = (index) => {
    console.log(`updateIndex: ${index}`);
    setLocalIndex(index);
  };

  return (
    <IndexContext.Provider value={{ index, setIndex: updateIndex }}>
      <InstantSearch indexName={locaIndex} searchClient={client.client}>
        <div className="slds-grid slds-wrap slds-m-top_x-large">
          <div className="slds-col slds-size_12-of-12">
            <SearchInput />
          </div>

          <div className="slds-col slds-size_12-of-12 slds-m-bottom_x-large"></div>

          <div className="slds-col slds-small-size_12-of-12 slds-large-size_3-of-12 ">
            <Refinement />
          </div>

          <div className="slds-col slds-small-size_12-of-12 slds-large-size_9-of-12">
            {/* <Summary /> */}

            <ItemTable />

            <Navigation />
          </div>
        </div>
      </InstantSearch>
    </IndexContext.Provider>
  );
};

export default Layout;
